<template>
    <el-dialog
        class="editDialog"
        :append-to-body="true"
        width="600px"
        :visible.sync="showEditDialog"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        @close="$emit('update:isShow', false)"
        :show-close="true"

    >

        <div slot="title" class="title">{{ options.id ? '编辑' : '新增' }}用户</div>

        <div class="content" v-loading="loading">
            <el-form label-width="6em" :model="form" :rules="rules" ref="form">

                <el-form-item label="姓名" prop="name" ref="name">
                    <el-input v-model="form.name" placeholder="请输入姓名" @keyup.enter.native="submit"></el-input>
                </el-form-item>

                <el-form-item label="所属部门" prop="deptId" ref="deptId">

                    <el-cascader
                        v-model="form.deptId"
                        :options="treeselectOptions"
                        :props="{ expandTrigger: 'hover',checkStrictly:true,emitPath:false,label:'deptName',value:'id' }"
                        @change="changeDeptId"></el-cascader>

                </el-form-item>

                <el-form-item label="用户名" prop="userName" ref="userName">
                    <el-input v-model="form.userName" placeholder="请输入用户名" @keyup.enter.native="submit"></el-input>
                </el-form-item>

                <el-form-item label="密码" prop="password" ref="password">
                    <el-input type="password" placeholder="请输入密码" v-model="form.password" @keyup.enter.native="submit"></el-input>
                </el-form-item>

                <el-form-item label="确认密码" prop="rePassword" ref="rePassword">
                    <el-input type="password" placeholder="请再次输入密码" v-model="form.rePassword" @keyup.enter.native="submit"></el-input>
                </el-form-item>

            </el-form>
        </div>

        <div slot="footer" v-show="!loading">
            <el-button type="primary" :loading="submitLoading" @click="submit">保存</el-button>
            <el-button type="info" plain @click="$emit('update:isShow', false)">取消</el-button>
        </div>

    </el-dialog>

</template>
<script>

import mixinsForm from '@/mixins/form'
import {initTreeData} from "@/util/common";
import {PASSWORD} from "@/util/regular";
export default {
    components: {},
    mixins: [mixinsForm],
    props: {
        treeselectOptions: {
            type: Array,
            default:function(){
                return []
            }
        },
    },
    data() {
        return {
            api:{
                save:this.$api['admin/user'].save,
            },
            rules: {
                name: [{ required: true, message: '请输入用户姓名', trigger:['blur','change'] }],
                userName: [{ required: true, message: '请输入登录帐号', trigger:['blur','change'] }],
                password: [
                    { required: true, message: '请输入密码', trigger:['blur','change']},
                    { pattern:PASSWORD.value,message:'密码必须大于6位且包含字母和数字',trigger:['blur','change']},
                ],
                rePassword: [
                    { required: true, message: '请再次输入密码', trigger:['blur','change']},
                    {
                        trigger: ['change', 'blur'],
                        validator: (rule, value, callback) => {
                            if(value!=this.form.password){
                                callback(new Error('两次输入的密码不一致'));
                            };
                            callback();
                        }
                    }
                ],
                deptId: [{required: true, message: '请选择所属部门', trigger: ['blur','change']},],
            },
        };
    },
    created() {},
    methods: {
        changeDeptId(e){
            this.$refs.form.validateField('deptId');
        },
    },
};
</script>
